/* .App {
  text-align: center;
  
  background-color: #dedbd2;

} */

/* .App { */
  /* font-family: 'Arial', sans-serif; */
  /* color: #333;
  text-align: center;
  padding: 20px;
  background: linear-gradient(to right, #e2e2e2, #ffffff);
} */

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  border: 2px solid #ccc;
  border-radius: 50%;
  margin-right: 10px;
}

.title {
  font-size: 2rem;
  color: #0056b3;
}

/* .container {
  display: flex;
  flex-direction: column;
  align-items: center;
} */
.card {
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 500px;
  margin: 10px 0;
  transition: transform 0.3s ease;
    text-align: center;
}
/* .card {
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  width: 80%;
  max-width: 600px;
  
} */

.card:hover {
  transform: scale(1.05);
}

/* .button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #0056b3;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #003d7a;
} */

.error {
  color: red;
  font-size: 1.2rem;
  margin-top: 10px;
}

.qr-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qr-image {
  width: 150px;
  height: 250px;
  margin-bottom: 10px;
}
/* Container for the entire app */
.App {
  /* font-family: Arial, sans-serif; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to right, #626ED4, #ffffff);

}

.form-title {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
}

.info-text {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  font-size: 18px;
  color: #333;
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 90%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
}

.input-error {
  border-color: #ff4d4d;
}

.error-text {
  color: #ff4d4d;
  font-size: 14px;
  margin-top: 5px;
}

.submit-button,
.confirm-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover,
.confirm-button:hover {
  background-color: #0056b3;
}
/* Center and style thank you screen */
.thank-you-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to right, #e0f7fa, #b9fbc0);
  text-align: center;
  padding: 20px;
}

.thank-you-message {
  font-size: 2rem;
  color: #00796b;
  margin: 20px 0;
}

.thank-you-icon {
  width: 120px;
  height: 120px;
  margin: 20px 0;
}

.thank-you-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #00796b;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.thank-you-button:hover {
  background-color: #004d40;
}

/* Card styles for various sections */


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.error {
  color: red;
  font-size: 16px;
  margin: 10px 0;
}

/* Payment options styles */
.payment-options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.payment-options-container .card {
  cursor: pointer;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
}

.qr-container {
  text-align: center;
  margin-top: 20px;
  max-width: 300px;

}

.qr-image {
  width: 280px;
  height: 380px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #00796b;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #004d40;
}

/* Responsive styles */
@media (max-width: 600px) {
  .thank-you-message {
    font-size: 1.5rem;
  }

  .thank-you-icon {
    width: 100px;
    height: 100px;
  }

  .card {
    width: 90%;
  }

  .payment-options-container .card {
    width: 90%;
  }
}

.back-button {
  /* margin: 10px; */
  padding: 10px 20px;
  background-color: #ccc;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.back-button:hover {
  background-color: #bbb;
}
