.thank-you-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(to right, #e0f7fa, #b9fbc0);
    text-align: center;
  }
  
  .thank-you-message {
    font-size: 2rem;
    color: #00796b;
    margin: 20px 0;
  }
  .thank-you-message1 {
    font-size:1rem;
    color: #c72a90;
    margin: 20px 0;
  }
  
  .thank-you-icon {
    width: 120px;
    height: 120px;
    margin: 20px 0;
  }
  
  .thank-you-animation {
    animation: bounceIn 1.5s;
  }
  
  @keyframes bounceIn {
    0% { transform: scale(0); opacity: 0; }
    50% { transform: scale(1.1); opacity: 1; }
    100% { transform: scale(1); opacity: 1; }
  }
  
  .thank-you-button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    background-color: #00796b;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .thank-you-button:hover {
    background-color: #004d40;
  }
  